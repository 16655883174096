<template>
	<div class="content">
		<div class="logo"><img src="../../../assets/image/logo.png" ></img></div>
		<div class="uni-form-item uni-column">
			<input type="tel" class="uni-input" name="" v-model="ruleForm.email" placeholder="请输入邮箱" maxlength="20"/>
		</div>
		<div class="uni-form-item uni-column">
			<input type="password" class="uni-input" name="" v-model="ruleForm.password" placeholder="请输入密码" />
		</div>
		<div class="uni-form-item uni-column">
			<input type="password" class="uni-input" name="" v-model="ruleForm.code" placeholder="请输入密码" />
			<img :src="ruleForm.codeimg" class="yzm" @click="getcode"></img>
		</div>
		<div class="uni-btn">
			<button type="primary" @click="gotoLogin">登陆</button>
		</div>
		<div class="links"><div @tap="gotoForgetPassword">忘记密码？</div><div>|</div><div class="link-highlight" @tap="gotoRegistration">注册账号</div></div>
	</div>
</template>

<script>
	import httpapi from "@/utils/httpapi"
	import captchaApi from '@/api/common/captcha.js'
	import {
		setloginuser
		
	} from '@/utils/util'
	import md5 from 'js-md5'
	export default {
		data() {
			return {
				ruleForm:{
					uuid:"",
					email:"",
					password:"",
					code:"",
					codeimg:""
				}
			}
		},
		mounted() {
			// 获取图形验证码
			this.getcode()
		},
		methods: {
			getcode() {
				var _this = this
				captchaApi.createimg(res => {
					console.log(res, 'getcode.res');
					if (res.code == 200) {
						_this.ruleForm.codeimg = `data:image/png;base64,${res.img}`
						_this.ruleForm.uuid = res.uuid
					} else {
						_this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			gotoLogin: function () {
				var _this = this
				if (_this.ruleForm.email.length <= 0) {
					_this.$message({
						type: 'error',
						message: '邮箱不能为空'
					})
					return;
				}
				console.log(_this.ruleForm, 'ruleForm');
				if (_this.ruleForm.password.length <= 0) {
					_this.$message({
						type: 'error',
						message: '密码不能为空'
					})
					return;
				}
				httpapi.post('/customer/auth/user/login', {
					email: _this.ruleForm.email,
					pwd: _this.ruleForm.password,
					uuid: _this.ruleForm.uuid,
					yzm: _this.ruleForm.code,
				}).then(res => {
					console.log(res, 'login.res');
					if (res.code == 200) {
						// this.$store.commit('customerloginin', {
						// 	"uid": res.data.uid,
						// 	"nickname": res.data.nickname,
						// 	"unitName": res.data.unitName,
						// 	"unitno": res.data.unitno,
						// 	"permissions":res.data.permissions,
						// 	token: res.data.token,
						// 	usertype:res.data.usertype,
						// 	mchno:res.data.mchno,
						// 	shopno:res.data.shopno
						// })
						// sessionStorage.setItem("token", res.data.token);
						// sessionStorage.setItem("usertype", res.data.usertype);
						// setCookie("login_uid", res.data.uid, 3);
						// setCookie("login_uname", res.data.nickname, 3);
						setloginuser(res.data)
						this.$router.push({
							path: '/app/qiyewx/index'
						})
					} else {
						_this.getcode()
						_this.$message({
							type: 'error',
							message: res.msg
						})
						console.log(5555);
					}
				})
				
			},
			gotoRegistration: function () {
				
			},
			gotoForgetPassword: function () {
				
			}
		}
	}
</script>

<style lang="less" scoped>
	.content{
		padding: 50px;
	}
	.logo{
	    text-align: center;
		padding-bottom: 50px;
		img{
		    width: 250px;
		    margin: 0 0 60px;
		}
	}
	.uni-form-item{
		margin-bottom: 40px;
		padding: 0;
		border-bottom: 1px solid #e3e3e3;
		display:flex;
		justify-content: space-between;
		.uni-input{
			font-size: 14px;
			padding: 7px 0;
			border: none;
			outline: none;
		}
		.yzm{
			width:100px;
		}
	}
	.uni-btn{
		text-align: center;
	}
	button[type="primary"]{
		background:linear-gradient(to right,#00B9E5,#00DBC9);
		border-radius: 0;
		font-size: 14px;
		margin-top: 60px;
		border: none;
		color: #fff;
		padding: 10px 100px;
		width: 100%;
	}
	.links{
		text-align: center;
		margin-top: 40px;
		font-size: 26px;
		color: #999;
		div{
			display: inline-block;
			vertical-align: top;
			margin: 0 10px;
		}
		.link-highlight{
			color:  #00B9E5
		}
	}
</style>
